<template>
  <div class="product">
    <!-- 搜索框 -->
    <el-row :gutter="20">
      <el-col :span="8">
        <el-input placeholder="请输入内容" clearable>
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </el-col>
    </el-row>

    <!-- main -->
    <div class="label">项目产品</div>
    <div class="main">
      <div class="app-item" v-for="item in appList" :key="item.id">
        <div class="logo">
          <img :src="item.logo" alt="" />
        </div>
        <div class="data-wrap" @click="toDefault(item.id)">
          <div class="title">{{ item.name }}</div>
          <div class="date">开发时间:{{ item.date }}</div>
          <div class="data">{{ item.text }}</div>
        </div>
      </div>
    </div>
     <div class="label">在线环境</div>
    <div class="main">
      <div class="app-item" @click="useOnlineServer">
        <div class="logo">
          <img
            src="http://120.24.182.152:5555/s/static/s/image_files/users_image/jupyter.png"
            alt=""
          />
        </div>
        <div class="data-wrap">
          <div class="title"> notebook</div>
          <div class="date">2021-10-04</div>
          <div class="data">在线 jupyter notebook 环境</div>
        </div>
      </div>
    </div>

    <!-- 输入密码 -->
    <el-dialog
  title="输入密码"
  :visible.sync="dialogVisible"
  width="50%"
  >
 <el-form :model="useServer" :rules="addRules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
  <el-form-item label="密码" prop="password">
    <el-input v-model="useServer.password"></el-input>
  </el-form-item>
 </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
</el-dialog>

    
  </div>
</template>

<script>
import formatDate from '../../../utils/formatDate'

export default {
  name: "product",
  data() {
    return {
       dialogVisible: false, // dailog
       appList:[],
       useServer:{
         password:''
       },

         // 校验规则
        addRules:{
            password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { min: 8, max: 16, message: '格式错误', trigger: 'blur' }
          ],
        },

    };
  },
  beforeMount() {
      if(this.$store.state.productList.productList){
        this.appList = this.$store.state.productList.productList
      }else{
         this.getProdcuList()
      }
  },
  methods: {
    // 获取项目列表
    async getProdcuList(){
      const {data:res} = await this.$http.get('product_list/')
      if(res.code!=='200') return 
      this.appList = res.data
      // 时间数据格式化
      this.appList.forEach((v)=>{
        v.date = formatDate.format2(v.date)
      })
      // 保存 缓存
      this.$store.commit('productList/SAVE_PRODUCT_LIST',this.appList)
    },


    // 去详情页
    toDefault(id){
      this.$router.push({
        path:'/home/product/detail',
        query:{id}
      })
    },

    // 使用在线服务 
    useOnlineServer(){
      this.dialogVisible = true
    }
  },
};
</script>

<style lang='less' scoped>

.label{
  width: 100%;
  height: 40px;
  padding: 10px;
  margin-top: 15px;
}
.main {
  display: flex;
  flex-wrap: wrap;

  .app-item {
    background: #fff;
    padding-left: 5px;
    margin: 10px 10px;
    width: 300px;
    height: 120px;
    display: flex;
     cursor: pointer; // 显示手指
    .logo {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 90px;
        height: 90px;
        background: #b1b1b1;
      }
    }
    .data-wrap {
      flex: 2;
      padding: 9px 5px;
      .title {
        color: black;
        font-weight: 700;
        font-size: 18px;
      }
      .date {
        font-size: 10px;
        color: rgb(107, 107, 107);
        margin-top: 15px;
      }
      .data {
        color: rgb(107, 107, 107);
        font-size: 14px;
          display: -webkit-box;
        // 不换行
        overflow: hidden;
        -webkit-box-orient: vertical;
        // 俩点S
        -webkit-line-clamp: 2;
      }
    }
  }
}
</style>